<template>
    <div v-if="trigger && topicType == 6">
        
        <b-card v-for="(item, index) in topicData" style="border-radius: 20px;" :class="$store.state.appConfig.layout.skin == 'dark' ? 'custom-dark-card p-2': 'p-2'" >
            <h3 class="mb-1.25 font-weight-bolder">Homework</h3>        
            <div :class="
                    $store.state.appConfig.layout.skin == 'dark' ? 'w-100 dark-card' : 'w-100'
                ">
                <h4 class="mb-1.25 font-weight-bolder">PDF topic</h4>   
                <div class="d-flex justify-content-between">
                    <h5 class="text-primary">
                        {{ item.pdf }}
                    </h5>
                    <b-button class="btn rounded-pill " variant="primary" @click="showPDF">
                        {{ viewPDF ? 'hide' : 'View'}} 
                    </b-button>

                </div>
                <br>
                <embed
                    v-if="item.pdf_path && viewPDF"
                    :src="item.pdf_path"
                    type="application/pdf"
                    style="width: 100%; height: 600px;"
                />
                <div v-if="item.attach">
                    <h4 class="mb-1.25 font-weight-bolder">Attached PDF</h4> 
                    <div class="d-flex justify-content-between">
                    <h5 class="text-primary">
                        {{ item.attaches[0].pdf }}
                    </h5>
                    <b-button class="btn rounded-pill " variant="primary" @click="showAttachedPDF">
                        {{ viewAttachedPDF ? 'hide' : 'View'}} 
                    </b-button>

                </div>
                    <br>
                    <embed
                        v-if="item.attaches[0].pdf_path && viewAttachedPDF"
                        :src="item.attaches[0].pdf_path"
                        type="application/pdf"
                        style="width: 100%; height: 600px;"
                    />
                    <div v-if="authUser().user.type != 2">
                        <h5 class="mt-2">Add score</h5>
                        <div class="d-flex">
                            <div style="width: 100px;"> 
                                <validation-provider
                                        #default="{ errors }"
                                        name="grade"
                                        vid="grade"
                                        rules="required|max_value:100"
                                    >
                                        <b-input-group
                                            class="input-group-merge mr-1"
                                        >
                                            <b-form-input
                                                :disabled="item.attaches[0].length == 0"
                                                :class="item.attaches[0].length == 0 ? 'cursor-disabled' : ''"
                                                autocomplete="off"
                                                v-model="grade[index]"
                                                placeholder="0"
                                                id="grade"
                                                max="100"
                                                style="background-color: #f6f6f6;"
                                            />
                                            </b-input-group>
                                        <small class="text-danger float-left">{{ errors[0] }}</small>
                                </validation-provider>
                            </div>
                            <span style="margin: 10px 6px;"><h5>/ 100</h5></span>
                        </div>
                        <h5 class="mt-2">Comment</h5>
                        
                            <b-form-group
                                
                            >
                                <b-input-group
                                    class="input-group-merge mr-1"
                                >
                                    <b-form-textarea
                                        :disabled="item.attaches[0].length == 0"
                                        :class="item.attaches[0].length == 0 ? 'cursor-disabled' : ''"
                                        id="textarea-default"
                                        v-model="comment[index]"
                                        placeholder=""
                                        style="background-color: #f6f6f6;"
                                    ></b-form-textarea>
                                    </b-input-group>
                                
                            </b-form-group>
                        
                        <div class="d-flex justify-content-end">
                            
                            <b-button
                                variant="primary"
                                class="rounded-pill mt-2 pr-4 pl-4"
                                type="submit"
                                @click="submit(item.attaches[0].id, index)"
                            >
                            
                                save
                            </b-button>
                        </div>
                    </div>
                    <div v-else>
                        <h3 class="mt-2 mb-1.25 font-weight-bolder">Grade</h3>
                        <p style="font-size: 12px;" v-if="item.attaches[0].feedback.length > 0"> {{ item.attaches[0].feedback[0].grade }} </p>
                        <h3 class="mt-2 mb-1.25 font-weight-bolder">Feedback</h3>
                        <p style="font-size: 12px;" v-if="item.attaches[0].feedback.length > 0"> {{ item.attaches[0].feedback[0].comment }} </p>
                    </div>
                </div>
                
            </div>
        </b-card>
    </div>
</template>
<script>
    import Ripple from "vue-ripple-directive";
    import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
    import { mapGetters } from "vuex";
    import VueApexCharts from 'vue-apexcharts';
    import vSelect from "vue-select";
    import { ValidationProvider, ValidationObserver,extend } from 'vee-validate'
    import { required, email, between } from '@validations'
    import {
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BBadge,
    BCardText,
    BAvatar,
    VBTooltip,
    BLink,
    BSpinner,
    BPagination,
    BInputGroup,
    BFormInput,
    BFormTextarea,
    BFormFile,
    BForm,
    BFormGroup
    } from "bootstrap-vue";
    extend('max_value', {
        validate(value, { max }) {
            return value <= max;
        },
        params: ['max'],
        message: 'The {_field_} must be {max} or less.',
    });
    export default {
        props: [
            'topicData',
            'topicType'
        ],
        name: "topics",
        components: {
            BTable,
            BButton,
            BFormCheckbox,
            BCard,
            BRow,
            BCol,
            BBadge,
            BAvatar,
            BCardText,
            BLink,
            BSpinner,
            BPagination,
            ToastificationContent,
            vSelect,
            VueApexCharts,
            BInputGroup,
            BFormInput,
            BFormTextarea,
            BFormFile,
            BForm,
            ValidationProvider,
            ValidationObserver,
            BFormGroup
        },
        directives: {
            Ripple,
            "b-tooltip": VBTooltip,
        },
        computed: {
            ...mapGetters({
            
            }),
        },
        
        data() {
            return {
                viewPDF:false,
                viewAttachedPDF:false,
                grade: [],
                comment: [],
                trigger: true
            };
        },
        methods: {
            showPDF(){
                this.viewPDF = !this.viewPDF;
            },
            showAttachedPDF(){
                this.viewAttachedPDF = !this.viewAttachedPDF;
            },
            submit(attach_id,index){
                const payload = {
                    query:{ 
                        model_id: attach_id,
                        model_type: this.topicType == 1 ? 'Answer' : this.topicType == 2 ? 'Answer' : this.topicType == 3 ? 'OQAnswer' : 'attachs_p_d_f_s',
                        comment: this.comment[index],
                        grade: this.grade[index],
                    }
                }
                this.$store.dispatch('students/storeFeedback', payload).then(_=>{
                    
                    this.$emit('refresh')
                    this.$swal({
                        icon: 'success',
                        title: 'Saved Successfully!',
                        confirmButtonColor: '#E84185',
                    })
                }).catch(error => {
                    if (error.response) {
                        this.$swal({
                            icon: 'error',
                            title: `<h4>${error.response.data.message}</h4>`,
                            showConfirmButton: true,
                            confirmButtonColor: '#E84185',
                            allowOutsideClick: true
                        });
                    }
                });
            },
            
            init(){
                if(this.topicType == 6){
                    this.trigger = false
                    if (this.topicData[0].attach) {
                        this.topicData.forEach((el,index) => {
                            this.$set(this.grade, index, el.attaches[0].feedback.length > 0 ? el.attaches[0].feedback[0].grade : null);
                            this.$set(this.comment, index, el.attaches[0].feedback.length > 0 ? el.attaches[0].feedback[0].comment : null);
                        });
                    }
                    this.trigger = true
                }
            }
        },
        mounted(){
            this.init() 
        }
    };
</script>
<style lang="scss" scoped>
    .custom-input-grade {
        background-color: #f8f9fa; /* Gray background */
        width: 150px; /* Custom width */
        padding: 0.5rem; /* Optional: Adjust padding */
    }
    .custom-dark-card{
        border: 1px #3A3A3A solid;
        background-color: transparent;
    }
</style>